import { useDataContext } from '../context/DataProvider'
import styles from './WelcomeForRla.module.css'
import { LoadingIndicator } from 'components/LoadingIndicator'
import {
  Checkbox,
  FormControlLabel,
  Grow,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import {
  dropdownItemStyles,
  dropdownOutlineLabelStyles,
  textFieldOutlineLabelStyles,
} from 'utils/MuiStyles'
import { useRef, useState } from 'react'
import { isEmail } from 'utils/stringUtils'

type PageProps = {
  onContinue: () => void
  emailPlaceholder: string
  businessUnits: string[]
  locations: string[]
  otherLocationOptionText: string
  acceptedEmailDomains: string[]
}

export default function WelcomeForRla({
  onContinue,
  emailPlaceholder,
  businessUnits,
  locations,
  otherLocationOptionText,
  acceptedEmailDomains,
}: PageProps) {
  const { submission, setSubmission } = useDataContext()
  const [rerenderCount, setRerenderCount] = useState(0)
  const shouldValidate = useRef(false)
  const [otherLocation, setOtherLocation] = useState('')

  const getFirstNameErrorMessage = () => {
    if (shouldValidate.current && submission?.firstName?.trim() === '') {
      return 'Please input your first name'
    } else return ''
  }

  const getEmailErrorMessage = () => {
    if (!shouldValidate.current) {
      return ''
    }
    const email = submission?.email || ''
    const emailDomain = email.substring(email.lastIndexOf('@')) || ''
    if (!isEmail(email)) {
      return 'Please input your work email address'
    }

    const env = process.env.REACT_APP_ENV
    if (env !== 'dev' && !acceptedEmailDomains.includes(emailDomain)) {
      return 'Please use your RLA-related work email address'
    }
    return ''
  }

  const getManagerFirstNameErrorMessage = () => {
    if (shouldValidate.current && !submission?.managerFirstName?.trim()) {
      return 'Please input the manager’s name'
    } else return ''
  }

  const getManagerEmailErrorMessage = () => {
    if (!shouldValidate.current) {
      return ''
    }
    const email = submission?.managerEmail || ''
    const emailDomain = email.substring(email.lastIndexOf('@')) || ''
    if (!isEmail(email)) {
      return 'Please input your manager’s email address'
    }
    if (!acceptedEmailDomains.includes(emailDomain)) {
      return `Please use the direct manager's RLA-related work email address`
    }
    // check if the email is the same as the user's email
    if (email === submission?.email) {
      return `Please input your manager’s email address`
    }
    return ''
  }

  const getTncError = () => {
    if (shouldValidate.current && !submission?.tncAgreeAt) {
      return true
    } else return false
  }

  const getRlaChapterLeadDiscussionConfirmationError = () => {
    if (
      shouldValidate.current &&
      !submission?.rlaChapterLeadDiscussionConfirmationAt
    ) {
      return true
    } else return false
  }

  const getRlaTimeAndLeaveCommitmentConfirmationError = () => {
    if (
      shouldValidate.current &&
      !submission?.rlaTimeAndLeaveCommitmentConfirmationAt
    ) {
      return true
    } else return false
  }

  const getBusinessUnitErrorMessage = () => {
    if (shouldValidate.current && !submission?.businessUnit?.trim()) {
      return 'Please select your chapter area'
    } else return ''
  }

  const getLocationErrorMessage = () => {
    if (shouldValidate.current && !submission?.location?.trim()) {
      return 'Please select your location'
    } else return ''
  }

  const getOtherLocationErrorMessage = () => {
    if (
      shouldValidate.current &&
      submission?.location === otherLocationOptionText &&
      !otherLocation.trim()
    ) {
      return 'Please specify your location'
    } else return ''
  }

  const labelTncAgree = (
    <span>
      I agree to the terms of Mentem's{' '}
      <span style={{ textDecoration: 'underline' }}>
        <a href="https://www.mentem.co/privacy/">Privacy Policy</a>
      </span>
    </span>
  )

  const labelForAgree1 = (
    <span>
      I have spoken to my Chapter Lead about this learning opportunity prior to
      submitting my application
    </span>
  )

  const labelForAgree2 = (
    <span>
      I confirm I have considered the time needed, alongside leave plans, and
      can meet the requirements to complete the program
    </span>
  )

  const handleClickContinue = () => {
    shouldValidate.current = true

    if (
      getFirstNameErrorMessage() ||
      getEmailErrorMessage() ||
      getBusinessUnitErrorMessage() ||
      getLocationErrorMessage() ||
      getOtherLocationErrorMessage() ||
      getManagerFirstNameErrorMessage() ||
      getManagerEmailErrorMessage() ||
      getTncError() ||
      getRlaChapterLeadDiscussionConfirmationError() ||
      getRlaTimeAndLeaveCommitmentConfirmationError()
    ) {
      console.log('Invalid profile')
      setRerenderCount(rerenderCount + 1)
      return
    }

    // continue
    shouldValidate.current = false
    onContinue()
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.description} style={{ fontSize: '26px' }}>
          Ready to apply? Let's get started!
        </div>
        <div className={styles.instructions}></div>
        <div className={styles.formcontainer}>
          <TextField
            value={submission?.firstName || ''}
            label={getFirstNameErrorMessage() || 'First Name'}
            variant="outlined"
            sx={textFieldOutlineLabelStyles}
            onChange={(e) => {
              setSubmission({ ...submission!, firstName: e.target.value })
            }}
            error={getFirstNameErrorMessage() !== ''}
          />

          <TextField
            value={submission?.lastName || ''}
            label={'Last Name'}
            variant="outlined"
            sx={textFieldOutlineLabelStyles}
            onChange={(e) => {
              setSubmission({ ...submission!, lastName: e.target.value })
            }}
          />

          <TextField
            value={submission?.email || ''}
            label={
              getEmailErrorMessage() || emailPlaceholder || 'Business Email'
            }
            variant="outlined"
            sx={textFieldOutlineLabelStyles}
            error={getEmailErrorMessage() !== ''}
            onChange={(e) => {
              setSubmission({ ...submission!, email: e.target.value })
            }}
          />

          <TextField
            value={submission?.managerFirstName || ''}
            label={
              getManagerFirstNameErrorMessage() || `Chapter Lead’s First Name`
            }
            variant="outlined"
            sx={textFieldOutlineLabelStyles}
            error={getManagerFirstNameErrorMessage() !== ''}
            onChange={(e) => {
              setSubmission({
                ...submission!,
                managerFirstName: e.target.value,
              })
            }}
          />

          <TextField
            value={submission?.managerEmail || ''}
            label={getManagerEmailErrorMessage() || 'Chapter Lead’s Email'}
            variant="outlined"
            sx={textFieldOutlineLabelStyles}
            error={getManagerEmailErrorMessage() !== ''}
            onChange={(e) => {
              setSubmission({ ...submission!, managerEmail: e.target.value })
            }}
          />

          <Select
            style={{
              width: '100%',
              border: '1px solid rgba(230, 230, 230, 1)',
              backgroundColor: 'rgba(255, 255, 255, 1)',
            }}
            variant="outlined"
            sx={dropdownOutlineLabelStyles}
            displayEmpty
            renderValue={(selected) => {
              const errorMessage = getBusinessUnitErrorMessage()
              return errorMessage ? (
                <div style={{ color: 'rgba(211,47,41,1)' }}>{errorMessage}</div>
              ) : submission?.businessUnit ? (
                <div style={{ color: 'rgba(0,0,0,1)' }}>
                  {submission?.businessUnit}
                </div>
              ) : (
                <div style={{ color: 'rgba(0,0,0,0.6)' }}>Chapter Area</div>
              )
            }}
            value={submission?.businessUnit || ''}
            label="Chapter Area"
            onChange={(e) => {
              setSubmission({ ...submission!, businessUnit: e.target.value })
            }}
          >
            <MenuItem disabled value="">
              <div style={{ color: 'rgba(0,0,0,0.9)' }}>Chapter Area</div>
            </MenuItem>
            {businessUnits.map((unit) => (
              <MenuItem value={unit} sx={dropdownItemStyles} key={unit}>
                {unit}
              </MenuItem>
            ))}
          </Select>

          <Select
            style={{
              width: '100%',
              border: '1px solid rgba(230, 230, 230, 1)',
              backgroundColor: 'rgba(255, 255, 255, 1)',
            }}
            variant="outlined"
            sx={dropdownOutlineLabelStyles}
            displayEmpty
            renderValue={(selected) => {
              const errorMessage = getLocationErrorMessage()
              return errorMessage ? (
                <div style={{ color: 'rgba(211,47,41,1)' }}>{errorMessage}</div>
              ) : submission?.location ? (
                <div style={{ color: 'rgba(0,0,0,1)' }}>
                  {submission?.location}
                </div>
              ) : (
                <div style={{ color: 'rgba(0,0,0,0.6)' }}>Location</div>
              )
            }}
            value={submission?.location || ''}
            label="Location"
            onChange={(e) => {
              if (e.target.value !== otherLocationOptionText) {
                // remove otherLocation if it was set
                setSubmission({ ...submission!, otherLocation: undefined })
              }
              setSubmission({ ...submission!, location: e.target.value })
            }}
          >
            <MenuItem disabled value="">
              <div style={{ color: 'rgba(0,0,0,0.9)' }}>Location</div>
            </MenuItem>
            {locations.map((unit) => (
              <MenuItem value={unit} sx={dropdownItemStyles} key={unit}>
                {unit}
              </MenuItem>
            ))}
          </Select>

          {submission?.location === otherLocationOptionText && (
            <TextField
              defaultValue={
                submission?.location === otherLocationOptionText
                  ? ''
                  : otherLocation
              }
              label={getOtherLocationErrorMessage() || 'Other location'}
              variant="outlined"
              sx={textFieldOutlineLabelStyles}
              error={getOtherLocationErrorMessage() !== ''}
              onChange={(e) => {
                setOtherLocation(e.target.value)
                setSubmission({ ...submission!, otherLocation: e.target.value })
              }}
            />
          )}

          <TextField
            value={submission?.linkedIn || ''}
            label="LinkedIn Profile URL (Optional)"
            variant="outlined"
            sx={textFieldOutlineLabelStyles}
            onChange={(e) => {
              setSubmission({ ...submission!, linkedIn: e.target.value })
            }}
          />

          <div className={styles.checkboxList}>
            <div className={styles.checkboxcontainer}>
              <FormControlLabel
                required
                control={
                  <Checkbox
                    checked={submission?.tncAgreeAt ? true : false}
                    sx={{
                      '&.Mui-checked': {
                        color: 'rgba(186, 97, 255, 1)',
                      },
                    }}
                    onChange={(e) => {
                      setSubmission({
                        ...submission!,
                        tncAgreeAt: e.target.checked ? new Date() : undefined,
                      })
                    }}
                  />
                }
                label={labelTncAgree}
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 28 },
                }}
              />
              <Grow in={getTncError()} style={{ transformOrigin: '0 50% 0' }}>
                <span className={styles.error}>Required</span>
              </Grow>
            </div>

            <div className={styles.checkboxcontainer}>
              <FormControlLabel
                required
                control={
                  <Checkbox
                    checked={
                      submission?.rlaChapterLeadDiscussionConfirmationAt
                        ? true
                        : false
                    }
                    sx={{
                      '&.Mui-checked': {
                        color: 'rgba(186, 97, 255, 1)',
                      },
                    }}
                    onChange={(e) => {
                      setSubmission({
                        ...submission!,
                        rlaChapterLeadDiscussionConfirmationAt: e.target.checked
                          ? new Date()
                          : undefined,
                      })
                    }}
                  />
                }
                label={labelForAgree1}
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 28 },
                  textAlign: 'left',
                }}
              />
              <Grow
                in={getRlaChapterLeadDiscussionConfirmationError()}
                style={{ transformOrigin: '0 50% 0' }}
              >
                <span className={styles.error}>Required</span>
              </Grow>
            </div>

            <div className={styles.checkboxcontainer}>
              <FormControlLabel
                required
                control={
                  <Checkbox
                    checked={
                      submission?.rlaTimeAndLeaveCommitmentConfirmationAt
                        ? true
                        : false
                    }
                    sx={{
                      '&.Mui-checked': {
                        color: 'rgba(186, 97, 255, 1)',
                      },
                    }}
                    onChange={(e) => {
                      setSubmission({
                        ...submission!,
                        rlaTimeAndLeaveCommitmentConfirmationAt: e.target
                          .checked
                          ? new Date()
                          : undefined,
                      })
                    }}
                  />
                }
                label={labelForAgree2}
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 28 },
                  textAlign: 'left',
                }}
              />
              <Grow
                in={getRlaTimeAndLeaveCommitmentConfirmationError()}
                style={{ transformOrigin: '0 50% 0' }}
              >
                <span className={styles.error}>Required</span>
              </Grow>
            </div>
          </div>

          {submission?.program ? (
            <button
              onClick={handleClickContinue}
              className={styles.submitbutton}
            >
              Continue
            </button>
          ) : (
            <LoadingIndicator />
          )}
        </div>
      </div>
    </div>
  )
}
