import { useDataContext } from '../context/DataProvider'
import styles from './FormForRla.module.css'
import { useEffect, useRef, useState } from 'react'
import { API } from 'apis/API'
import { CreateActivityDto, CreateSubmissionDto } from 'apis/models/API.dto'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Flicking, { MoveEndEvent } from '@egjs/react-flicking'
import '@egjs/react-flicking/dist/flicking.css'
import { Footer } from 'components/Footer'
import { LoadingIndicator } from 'components/LoadingIndicator'
import { useMediaQuery } from '@mui/material'
import WelcomeForRla from 'components/WelcomeForRla'
import QuestionsForRla from 'components/QuestionsForRla'
import OptionsForRla from 'components/OptionsForRla'
import ThankYouForRla from 'components/ThankYouForRla'
import { Helmet } from 'react-helmet'

export default function FormForRla() {
  const navigate = useNavigate()
  const {
    submission,
    setSubmission,
    program,
    checkProgram,
    setProgramCode,
    setClientCode,
  } = useDataContext()
  const flicking = useRef<Flicking>(null)
  const location = useLocation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { subpage } = useParams()
  const isMobile = useMediaQuery('(max-width: 768px)')
  // hard code
  const clientCode = 'rla'
  const programCode = 'data-analytics'

  //////////////////////////
  // interactions
  //////////////////////////
  const triggerNextPage = async (page: number) => {
    if (page === 1) {
      const dto: CreateActivityDto = {
        program: program!._id,
        firstName: submission?.firstName || '',
        lastName: submission?.lastName,
        email: submission?.email || '',
        managerFirstName: submission?.managerFirstName || '',
        managerEmail: submission?.managerEmail,
        businessUnit: submission?.businessUnit,
        location: submission?.location,
        linkedIn: submission?.linkedIn,
        tncAgreeAt: submission?.tncAgreeAt,
        rlaChapterLeadDiscussionConfirmationAt:
          submission?.rlaChapterLeadDiscussionConfirmationAt,
        rlaTimeAndLeaveCommitmentConfirmationAt:
          submission?.rlaTimeAndLeaveCommitmentConfirmationAt,
        action: 'start',
      }
      if (isSubmitting) {
        return
      }
      // lock, submit, unlock
      setIsSubmitting(true)
      await API.createActivity(dto)
        .then((response) => {
          console.log('Response:', response)
        })
        .catch((error) => {
          console.log('Error:', error)
        })
        .finally(() => {
          setIsSubmitting(false)
        })

      navigate(`/${clientCode}/${programCode}/form/questions`)
    } else if (page === 2) {
      navigate(`/${clientCode}/${programCode}/form/options`)
    } else if (page === 3) {
      const dto: CreateSubmissionDto = {
        program: program!._id,
        firstName: submission?.firstName || '',
        lastName: submission?.lastName,
        email: submission?.email || '',
        managerFirstName: submission?.managerFirstName || '',
        managerEmail: submission?.managerEmail,
        businessUnit: submission?.businessUnit,
        location: submission?.location,
        otherLocation: submission?.otherLocation,
        linkedIn: submission?.linkedIn,
        //remove the field placeholder in the question object
        questions: submission?.questions?.map((question) => {
          const { placeholder, ...rest } = question
          return rest
        }),
        dates: submission?.dates,
        tncAgreeAt: submission?.tncAgreeAt,
        rlaChapterLeadDiscussionConfirmationAt:
          submission?.rlaChapterLeadDiscussionConfirmationAt,
        rlaTimeAndLeaveCommitmentConfirmationAt:
          submission?.rlaTimeAndLeaveCommitmentConfirmationAt,
        criteriaJob: submission?.criteriaJob,
        returnURL: submission?.returnURL,
      }
      console.log('Submission:', dto)
      if (isSubmitting) {
        return
      }

      //lock, submit, unlock
      setIsSubmitting(true)
      await API.createOrUpdateSubmission(submission?._id, dto)
        .then((response) => {
          console.log('Response:', response)
          if (response) {
            console.log('Success')
            navigate(`/${clientCode}/${programCode}/form/thankyou`, {
              replace: true,
            })
          } else {
            console.log('Error')
          }
        })
        .catch((error) => {
          console.log('Error:', error)
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    }
  }

  const handleMoveEnd = (e: MoveEndEvent<Flicking>) => {
    // console.log('MoveEnd:', e)
  }

  //////////////////////////
  // useEffect
  //////////////////////////
  useEffect(() => {
    // console.log('Form:', clientCode, programCode)
    const tmpClientCode = clientCode || 'rla'
    checkProgram(tmpClientCode, programCode)
  }, [checkProgram, clientCode, programCode])

  // page changed
  useEffect(() => {
    console.log(`useEffect Page changed: ${subpage}`)
    if (subpage && !submission) {
      //landed on other form pages before init, redirect to form root
      navigate(`/${clientCode}/${programCode}/form`)
      showPage(0)
    } //switch page
    else if (!subpage) {
      //url is form root, states are other subpages
      if (flicking.current?.index !== 0) {
        //user navigated to other pages ->
        //redirect to root and prevent browser back
        showPage(0)

        //navigate same page again, prevent browser forward
        navigate(`/${clientCode}/${programCode}/form`)
      }
    } else if (subpage === 'questions') {
      showPage(1)
    } else if (subpage === 'options') {
      showPage(2)
    } else if (subpage === 'thankyou') {
      showPage(3)
      console.log('clean data')
      setClientCode(undefined)
      setProgramCode(undefined)
      setSubmission(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]) //first trigger is slug undefined

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [location.pathname])

  const showPage = (page: number) => {
    flicking.current?.stopAnimation()
    flicking.current?.moveTo(page)
  }

  useEffect(() => {
    flicking.current?.resize()
  }, [submission])

  return (
    <div className={styles.full}>
      {program && program?.name && (
        <Helmet>
          <title>{program?.name}</title>
        </Helmet>
      )}
      <div className={styles.overlay} />
      <div className={styles.backgroundimage} />

      <div className={styles.full}>
        {isSubmitting && (
          <div
            style={{
              position: 'fixed',
              width: '100vw',
              height: '100vh',
              zIndex: '100000',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <LoadingIndicator />
          </div>
        )}
        <div
          style={{
            backgroundImage: `url(${program?.components.sessionForm.bannerBackgroundImageUrl})`,
            minHeight: '360px',
          }}
          className={styles.banner}
        >
          <div className={styles.bannercontent}>
            <p style={{ fontSize: '36px', fontWeight: '700' }}>
              {program?.name || ''}
            </p>
            {!isMobile && (
              <div style={{ fontSize: '27px', marginTop: '16px' }}>
                Expression of interest form
              </div>
            )}
            <div style={{ marginTop: '16px' }}>
              <img src="/arrow-down-black.png" alt="down" />
            </div>
          </div>
        </div>

        <Flicking
          className={styles.container}
          align="prev"
          disableOnInit
          ref={flicking}
          adaptive={true}
          onMoveEnd={handleMoveEnd}
          style={{
            backgroundImage: `url(https://content.mentem.co/eoi/rla/form-bg-rla.png)`,
          }}
        >
          <div className={styles.pagecontainer} id="form">
            <WelcomeForRla
              emailPlaceholder={
                program?.components.sessionForm.emailPlaceholder || ''
              }
              businessUnits={
                program?.components.sessionForm.businessUnits || []
              }
              locations={program?.components.sessionForm.locations || []}
              otherLocationOptionText={
                program?.components.sessionForm.otherLocationOptionText || ''
              }
              acceptedEmailDomains={
                program?.components.sessionForm.acceptedEmailDomains || []
              }
              onContinue={() => triggerNextPage(1)}
            />
          </div>
          <div className={styles.pagecontainer} id="questions">
            <QuestionsForRla
              questions={program?.components.sessionForm?.questions || []}
              onContinue={() => triggerNextPage(2)}
            />
          </div>
          <div className={styles.pagecontainer} id="options">
            <OptionsForRla
              options={
                program?.components.sessionForm?.preferredCourseDates || []
              }
              onContinue={() => triggerNextPage(3)}
            />
          </div>
          <div className={styles.pagecontainer} id="thankyou">
            <ThankYouForRla />
          </div>
        </Flicking>
        <Footer backgroundColor="#0B2048" />
      </div>
    </div>
  )
}
